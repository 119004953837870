import MobileDetect from 'mobile-detect';
import { EGrade } from '../constants';

export class AppHelper {
    public static enterFullscreen = (element: any) => {
        if (element.requestFullscreen) {
            element.requestFullscreen();
        } else if (element.webkitRequestFullscreen) {
            // For Safari
            element.webkitRequestFullscreen();
        } else if (element.msRequestFullscreen) {
            // For IE/Edge
            element.msRequestFullscreen();
        } else {
            console.warn('Fullscreen API is not supported.');
        }
    };

    public static getGradeName = (grade: EGrade): string => {
        switch (grade) {
            case EGrade.UNASSESSED:
                return 'Unassessed';
            case EGrade.PRE_K:
                return 'Pre-K';
            case EGrade.KINDERGARTEN:
                return 'Kindergarten';
            case EGrade.GRADE_1:
                return '1st grade';
            case EGrade.GRADE_2:
                return '2nd grade';
            case EGrade.GRADE_3:
                return '3rd grade';
            case EGrade.GRADE_4:
                return '4th grade';
            case EGrade.GRADE_5:
                return '5th grade';
            case EGrade.GRADE_6:
                return '6th grade';
            case EGrade.GRADE_7:
                return '7th grade';
            case EGrade.GRADE_8:
                return '8th grade';
            case EGrade.GRADE_9:
                return '9th grade';
            case EGrade.GRADE_10:
                return '10th grade';
            case EGrade.GRADE_11:
                return '11th grade';
            case EGrade.GRADE_12:
                return '12th grade';
            case EGrade.BACHELORS:
                return 'College';
            case EGrade.MASTERS:
                return 'PhD';
            case EGrade.PHD:
                return 'Genius';
            default:
                throw new Error(`Invalid grade, ${grade}`);
        }
    };

    public static getLevelName = (grade: EGrade): string => {
        switch (grade) {
            case EGrade.PRE_K:
                return 'Level 1';
            case EGrade.KINDERGARTEN:
                return 'Level 2';
            case EGrade.GRADE_1:
                return 'Level 3';
            case EGrade.GRADE_2:
                return 'Level 4';
            case EGrade.GRADE_3:
                return 'Level 5';
            case EGrade.GRADE_4:
                return 'Level 6';
            case EGrade.GRADE_5:
                return 'Level 7';
            case EGrade.GRADE_6:
                return 'Level 8';
            case EGrade.GRADE_7:
                return 'Level 9';
            case EGrade.GRADE_8:
                return 'Level 10';
            case EGrade.GRADE_9:
                return 'Level 11';
            case EGrade.GRADE_10:
                return 'Level 12';
            case EGrade.GRADE_11:
                return 'Level 13';
            case EGrade.GRADE_12:
                return 'Level 14';
            case EGrade.BACHELORS:
                return 'Level 15';
            case EGrade.MASTERS:
                return 'Level 16';
            case EGrade.PHD:
                return 'Level 17';
            default:
                throw new Error(`Invalid level, ${grade}`);
        }
    };

    public static getVisibleGrades = (): EGrade[] => {
        return Object.values(EGrade).filter((g) => g !== EGrade.UNASSESSED);
    };

    public static isMobileDevice = (): boolean => {
        const md = new MobileDetect(navigator.userAgent);
        return !!md.mobile();
    };
}
