export class StringHelper {
    public static isEmpty(str: string | null): boolean {
        return !str || str.length === 0;
    }

    public static isNotEmpty(str: string | null): boolean {
        return !this.isEmpty(str);
    }

    public static replaceWordInSentence(
        word: string,
        sentence: string
    ): string {
        let regexp = new RegExp(`\\b${word}\\b`, 'i');
        if (regexp.test(sentence)) {
            const matches = sentence.match(regexp);
            return sentence.replace(regexp, `*${matches?.[0] || word}*`);
        }
        regexp = new RegExp(`\\b${word}`, 'i');
        const matches = sentence.match(regexp);
        return sentence.replace(regexp, `*${matches?.[0] || word}*`);
    }

    public static getCommonPrefix(str1: string, str2: string): string {
        let commonPrefix = '';
        for (let i = 0; i < Math.min(str1.length, str2.length); i++) {
            if (str1[i] === str2[i]) {
                commonPrefix += str1[i];
            } else {
                break;
            }
        }
        return commonPrefix;
    }
}
