import { createTheme } from '@mui/material/styles';
import mainTheme from './main';

const logoLargeTheme = createTheme(mainTheme, {
    typography: {
        body1: {
            fontSize: '6rem',
            [mainTheme.breakpoints.down('sm')]: {
                fontSize: '2.4rem',
            },
        },
    },
    components: {
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    fontSize: '4rem',
                    [mainTheme.breakpoints.down('sm')]: {
                        fontSize: '1.8rem',
                    },
                },
            },
        },
    },
});

export default logoLargeTheme;
