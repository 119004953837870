import React from 'react';
import Box from '@mui/material/Box';
import LogoComponent from './Logo';
import Fab from '@mui/material/Fab';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Settings from '@mui/icons-material/Settings';
import { grey } from '@mui/material/colors';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, setPage, setProfile } from '../redux/store';
import { ButtonBase, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import settingsTheme from '../themes/settings';
import { EPage } from '../constants';

const MenuComponent: React.FC = () => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const profile = useSelector((state: RootState) => state.app.profile);
    const open = Boolean(anchorEl);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleProfileClick = () => {
        handleClose();
        dispatch(setPage(EPage.PROFILE));
    };
    const handleTopicsClick = () => {
        handleClose();
        dispatch(setPage(EPage.SELECT_TOPIC));
    };
    const handleLogoutClick = () => {
        dispatch(setProfile(undefined));
        dispatch(setPage(undefined));
        navigate('');
    };
    const handleLogoClick = () => {
        dispatch(setPage(EPage.SELECT_TOPIC));
    };
    return (
        <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
                position: 'fixed',
                zIndex: 1000,
                width: '100%',
                boxSizing: 'border-box',
                padding: '0.5rem',
                backgroundColor: grey[900],
            }}
        >
            <ButtonBase onClick={handleLogoClick}>
                <LogoComponent size="small" />
            </ButtonBase>
            <Box
                display="flex"
                alignItems="center"
                sx={{ gap: { xs: 1, sm: 1, md: 2 } }}
            >
                {profile && (
                    <>
                        <Typography variant="caption" color="white">
                            {profile.name}
                        </Typography>
                        <Fab
                            id="basic-button"
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick}
                        >
                            <Settings />
                        </Fab>
                    </>
                )}
                <ThemeProvider theme={settingsTheme}>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        <MenuItem onClick={handleProfileClick}>
                            Profile
                        </MenuItem>
                        <MenuItem onClick={handleTopicsClick}>Topics</MenuItem>
                        <MenuItem onClick={handleLogoutClick}>Log out</MenuItem>
                    </Menu>
                </ThemeProvider>
            </Box>
        </Box>
    );
};

export default MenuComponent;
