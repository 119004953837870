import { createTheme } from '@mui/material/styles';
import mainTheme from './main';

const settingsTheme = createTheme(mainTheme, {
    components: {
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    fontSize: '1.5rem',
                    textTransform: 'uppercase',
                },
            },
        },
    },
});

export default settingsTheme;
