// src/App.tsx
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState, setAppState, setProfile } from './redux/store';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { IProfile } from './model';
import { EAppState, PROFILE_ID_PARAM } from './constants';
import StartupScreen from './screens/Startup';
import MainScreen from './screens/Main';
import Box from '@mui/material/Box';
import MenuComponent from './components/Menu';

const App: React.FC = () => {
    const appState = useSelector((state: RootState) => state.app.appState);
    const dispatch = useDispatch();
    const location = useLocation();
    const apiUrl = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();

    const fetchProfile = async () => {
        const queryParams = new URLSearchParams(location.search);
        const key = queryParams.get(PROFILE_ID_PARAM);
        if (key) {
            try {
                const response = await axios.get(
                    `${apiUrl}/profile?${PROFILE_ID_PARAM}=${key}`
                );
                if (response?.data?.profile?.key) {
                    const savedProfile: IProfile = response.data.profile;
                    dispatch(setProfile(savedProfile));
                    if (appState !== EAppState.PLAYING) {
                        dispatch(setAppState(EAppState.LOADED));
                    }
                } else {
                    navigate('');
                    dispatch(setAppState(EAppState.LOADED));
                }
            } catch (error) {
                console.error('Error starting app:', error);
            }
        } else if (appState !== EAppState.PLAYING) {
            dispatch(setAppState(EAppState.LOADED));
        }
    };

    useEffect(() => {
        fetchProfile();
    }, [location.search]);

    if (appState !== EAppState.PLAYING) {
        return <StartupScreen />;
    }

    return (
        <Box
            display="flex"
            flexDirection="column"
            sx={{ width: '100vw', height: '100vh' }}
        >
            <MenuComponent />
            <Box
                sx={{
                    flex: 1,
                    position: 'relative',
                    zIndex: 900,
                    paddingLeft: '2rem',
                    paddingRight: '2rem',
                    paddingTop: '72px',
                }}
            >
                <MainScreen />
            </Box>
        </Box>
    );
};

export default App;
