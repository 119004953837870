import { createTheme } from '@mui/material/styles';
import mainTheme from './main';

const spellingTheme = createTheme(mainTheme, {
    components: {
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    transform: 'translate(24px, 18px) scale(1.5)',
                    [mainTheme.breakpoints.down('sm')]: {
                        transform: 'translate(18px, 15px) scale(1)',
                    },
                },
                shrink: {
                    [mainTheme.breakpoints.down('sm')]: {
                        transform: 'translate(14px, -12px) scale(0.75)',
                    },
                },
            },
        },
    },
});

export default spellingTheme;
