export class NumberHelper {
    public static isEmpty(n: number | undefined | null): boolean {
        return !n;
    }

    public static isNotEmpty(n: number | undefined | null): boolean {
        return !this.isEmpty(n);
    }

    public static getScaledValueInRange(
        value: number,
        rangeMin: number,
        rangeMax: number,
        minValue: number,
        maxValue: number
    ): number {
        return Math.min(
            maxValue,
            Math.max(
                minValue,
                minValue +
                    ((rangeMax - value) / (rangeMax - rangeMin)) *
                        (maxValue - minValue)
            )
        );
    }
}
