// src/index.tsx
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter as Router } from 'react-router-dom';
import store from './redux/store';
import App from './App';
import './index.css';
import mainTheme from './themes/main';
import { Box } from '@mui/material';
import { createGenerateClassName, StylesProvider } from '@mui/styles';
import { ROOT_ELEMENT_ID } from './constants';

const container = document.getElementById(ROOT_ELEMENT_ID);
const root = createRoot(container!); // Create a root.

const generateClassName = createGenerateClassName({
    disableGlobal: true,
    productionPrefix: 'px', // Prefix to make it easier to recognize
});

root.render(
    <Box
        sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100vw',
            height: '100vh',
        }}
    >
        <Provider store={store}>
            <StylesProvider generateClassName={generateClassName}>
                <ThemeProvider theme={mainTheme}>
                    <CssBaseline />
                    <Router>
                        <App />
                    </Router>
                </ThemeProvider>
            </StylesProvider>
        </Provider>
    </Box>
);
