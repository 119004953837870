import React from 'react';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import Star from '@mui/icons-material/Star';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import logoSmallTheme from '../themes/logoSmall';
import logoLargeTheme from '../themes/logoLarge';

interface IProps {
    size: 'small' | 'large';
}

const LogoComponent: React.FC<IProps> = ({ size }) => {
    const theme = useTheme();
    const classes = makeStyles({
        bot: {
            color: theme.palette.primary.main,
        },
    })();
    return (
        <ThemeProvider
            theme={size === 'small' ? logoSmallTheme : logoLargeTheme}
        >
            <Typography variant="body1" sx={{ whiteSpace: 'nowrap' }}>
                <Star
                    sx={{
                        opacity: 0.54,
                    }}
                />
                spelling
                <span className={classes.bot}>bot</span>
                <Star
                    sx={{
                        opacity: 0.54,
                    }}
                />
            </Typography>
        </ThemeProvider>
    );
};

export default LogoComponent;
