import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import SpellingScreen from './Spelling';
import ProfileScreen from './Profile';
import TopicScreen from './Topics';
import { EPage } from '../constants';

const MainScreen: React.FC = () => {
    const profile = useSelector((state: RootState) => state.app.profile);
    const currentPage = useSelector(
        (state: RootState) => state.app.currentPage
    );

    if (!profile || currentPage === EPage.PROFILE) {
        return <ProfileScreen />;
    }

    if (currentPage === EPage.SPELLING) {
        return <SpellingScreen />;
    }

    return <TopicScreen />;
};

export default MainScreen;
