import React from 'react';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import Star from '@mui/icons-material/Star';
import { ITopic } from '../model';
import Box from '@mui/material/Box';
import { EGrade } from '../constants';

interface IProps {
    topic: ITopic;
    hideTitle?: boolean;
}

const TopicComponent: React.FC<IProps> = ({ topic, hideTitle }) => {
    const theme = useTheme();
    const classes = makeStyles({
        stars: {
            height: 48,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        buttonLike: {
            backgroundColor: theme.palette.primary.main,
            padding: '1.5rem 2.5rem',
            fontSize: '2rem !important',
            borderRadius: 4,
            color: theme.palette.primary.contrastText,
            textTransform: 'uppercase',
            fontWeight: theme.typography.fontWeightBold,
        },
    })();

    const score = topic.score || 0;
    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            sx={{ gap: { xs: 1, sm: 1, md: 2 } }}
        >
            {!hideTitle && (
                <Typography align="center" variant="caption">
                    {topic.grade === EGrade.UNASSESSED
                        ? score < 0
                            ? "You've started a"
                            : "Let's start with a"
                        : score !== 0
                          ? score < 0
                              ? 'In progress'
                              : `${score}%`
                          : 'Not started'}
                </Typography>
            )}
            {!hideTitle && (
                <Box>
                    <Typography align="center" variant="body2">
                        {topic.subject === topic.name ? '–' : topic.subject}
                    </Typography>
                    <Typography
                        align="center"
                        variant="body1"
                        color={
                            Math.abs(score) > 0
                                ? score === 1
                                    ? 'secondary'
                                    : 'primary'
                                : undefined
                        }
                    >
                        {topic.name}
                    </Typography>
                </Box>
            )}
            {topic.grade !== EGrade.UNASSESSED ? (
                <div className={classes.stars}>
                    {[
                        ...Array(Math.max(1, Math.round(Math.abs(score) / 20))),
                    ].map((_, starIndex) => (
                        <Star
                            key={starIndex}
                            color={
                                score <= 0
                                    ? undefined
                                    : score === 100
                                      ? 'secondary'
                                      : 'primary'
                            }
                            sx={{
                                opacity: score <= 0 ? 0.54 : undefined,
                            }}
                        />
                    ))}
                </div>
            ) : (
                <Typography variant="body2" className={classes.buttonLike}>
                    Continue
                </Typography>
            )}
        </Box>
    );
};

export default TopicComponent;
