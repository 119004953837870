import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, setAppState } from '../redux/store';
import { EAppState, ROOT_ELEMENT_ID } from '../constants';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import LogoComponent from '../components/Logo';
import { AppHelper } from '../helpers/AppHelper';
import screenfull from 'screenfull';
import { grey } from '@mui/material/colors';

const StartupScreen: React.FC = () => {
    const dispatch = useDispatch();
    const appState = useSelector((state: RootState) => state.app.appState);

    const handleClick = () => {
        console.log('AppHelper.isMobileDevice()', AppHelper.isMobileDevice());
        console.log('screenfull.isEnabled', screenfull.isEnabled);
        if (AppHelper.isMobileDevice()) {
            const rootElement = document.getElementById(ROOT_ELEMENT_ID);
            AppHelper.enterFullscreen(rootElement);
            if (screenfull.isEnabled) {
                screenfull.request(rootElement as Element);
            }
        }
        dispatch(setAppState(EAppState.PLAYING));
    };

    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{ padding: '2rem' }}
        >
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                sx={{ padding: '2rem', gap: 4 }}
            >
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    sx={{
                        gap: 4,
                        padding: '2rem',
                        backgroundColor: grey[900],
                        borderRadius: '1rem',
                        maxWidth: 800,
                    }}
                >
                    <LogoComponent size="large" />
                    <Typography align="center" variant="h4">
                        This app uses AI-generated word lists, sentences, and
                        voices to help improve your spelling.
                    </Typography>
                    <Button
                        variant="contained"
                        onClick={handleClick}
                        disabled={appState === EAppState.LOADING}
                    >
                        Start
                    </Button>
                </Box>
                <Typography align="center" variant="caption">
                    © 2024 Pixton Comics Inc. All rights reserved.{' '}
                    <Link href="">Terms of Use</Link> |{' '}
                    <Link href="">Privacy Policy</Link>
                </Typography>
            </Box>
        </Box>
    );
};

export default StartupScreen;
