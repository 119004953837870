import { createTheme } from '@mui/material/styles';
import mainTheme from './main';
import { grey } from '@mui/material/colors';

const topicsTheme = createTheme(mainTheme, {
    typography: {
        body1: {
            fontFamily: 'Merriweather, serif',
            fontSize: '2.4rem',
            lineHeight: 1.3,
            margin: 0,
            whiteSpace: 'nowrap',
            textTransform: 'uppercase',
            [mainTheme.breakpoints.down('md')]: {
                fontSize: '2rem',
            },
        },
        body2: {
            fontSize: '1.5rem',
            lineHeight: 1.3,
            margin: 0,
            whiteSpace: 'nowrap',
            [mainTheme.breakpoints.down('md')]: {
                fontSize: '1.2rem',
            },
        },
        caption: {
            fontSize: '1rem',
            textTransform: 'uppercase',
            margin: 0,
            opacity: 0.54,
        },
    },
    components: {
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: mainTheme.spacing(1),
                    padding: mainTheme.spacing(3),
                    paddingTop: mainTheme.spacing(2),
                    backgroundColor: grey[900],
                    [mainTheme.breakpoints.down('sm')]: {
                        padding: mainTheme.spacing(2),
                    },
                },
            },
        },
    },
});

export default topicsTheme;
