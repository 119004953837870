export const PROFILE_ID_PARAM = 'key';
export const ROOT_ELEMENT_ID = 'root';

export const enum EAppState {
    LOADING,
    LOADED,
    PLAYING,
}

export enum EGender {
    MALE = 'male',
    FEMALE = 'female',
}

export enum EGrade {
    UNASSESSED = 'X',
    PRE_K = 'PK',
    KINDERGARTEN = 'K',
    GRADE_1 = '1',
    GRADE_2 = '2',
    GRADE_3 = '3',
    GRADE_4 = '4',
    GRADE_5 = '5',
    GRADE_6 = '6',
    GRADE_7 = '7',
    GRADE_8 = '8',
    GRADE_9 = '9',
    GRADE_10 = '10',
    GRADE_11 = '11',
    GRADE_12 = '12',
    BACHELORS = 'B',
    MASTERS = 'M',
    PHD = 'P',
}

export enum EPage {
    SPELLING,
    PROFILE,
    SELECT_TOPIC,
}
