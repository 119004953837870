import { createTheme } from '@mui/material/styles';
import mainTheme from './main';

const logoSmallTheme = createTheme(mainTheme, {
    typography: {
        body1: {
            fontSize: '2rem',
            [mainTheme.breakpoints.down('sm')]: {
                fontSize: '1.8rem',
            },
        },
    },
    components: {
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    fontSize: '1.5rem',
                    [mainTheme.breakpoints.down('sm')]: {
                        fontSize: '1.3rem',
                    },
                },
            },
        },
    },
});

export default logoSmallTheme;
